import Vue from 'vue';

// eslint-disable-next-line no-unused-vars
import OC from './OC/index.js' // TODO: Not needed but L10n breaks if removed
import DiskbgHeader from './views/DiskbgHeader.vue'
import Nextcloud from './mixins/Nextcloud.js'

Vue.mixin(Nextcloud)

const View = Vue.extend(DiskbgHeader)
new View().$mount('#diskbg-header')
